"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabelaPrecoExportacao = exports.TabelaPreco = void 0;
const Produto_1 = require("./Produto");
const ProdutoExportacao_1 = require("./ProdutoExportacao");
class TabelaPreco {
    constructor(codtpr, destpr) {
        this.produtos = new Array();
        this.codtpr = codtpr;
        this.destpr = destpr;
    }
    adicionarProduto(produto) {
        if (this.produtoExisteTabela(produto)) {
            return console.log(`Produto de código ${produto.codpro} já incluído na tabela de preços`);
        }
        this.produtos.push(new Produto_1.ConcreteProduto(produto));
    }
    produtoExisteTabela(produto) {
        return this.produtos.filter(p => p.codpro === produto.codpro).length > 0;
    }
    obterProdutoTabela(produto) {
        const produtoTabela = this.produtos.find(p => p.codpro === produto.codpro);
        return produtoTabela ? new Produto_1.ConcreteProduto(produtoTabela) : undefined;
    }
}
exports.TabelaPreco = TabelaPreco;
class TabelaPrecoExportacao {
    constructor(codtpr, destpr) {
        this.produtos = new Array();
        this.codtpr = codtpr;
        this.destpr = destpr;
    }
    adicionarProduto(produto) {
        if (this.produtoExisteTabela(produto)) {
            return console.log(`Produto de código ${produto.codpro} já incluído na tabela de preços`);
        }
        this.produtos.push(new ProdutoExportacao_1.ConcreteProdutoExportacao(produto));
    }
    produtoExisteTabela(produto) {
        return this.produtos.filter(p => p.codpro === produto.codpro).length > 0;
    }
    obterProdutoTabela(produto) {
        const produtoTabela = this.produtos.find(p => p.codpro === produto.codpro);
        return produtoTabela ? new ProdutoExportacao_1.ConcreteProdutoExportacao(produtoTabela) : undefined;
    }
}
exports.TabelaPrecoExportacao = TabelaPrecoExportacao;
