<template>
  <div>
    <v-card>
      <v-card-text>
        Status - {{status}} -- {{ tabelaEspecial }}
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field outlined dense label="Número pedido cliente" v-model="pedrep"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea outlined dense label="Observação Pedido" v-model="obsped"></v-textarea>
          </v-col>

          <v-col cols="3" v-if="status == 'edicao'">
            <span><strong>Status:</strong>{{status}}</span>
            <v-select outlined label="Faixa de Preço Manual" :items="[{text:'Faixa 1',value:'FX1'},{text:'Faixa 2',value:'FX2'},{text:'Faixa 3',value:'FX3'}]" 
              v-model="faixaPreco" 
              @input="pedidoDomain.alterarFaixaPreco(faixaPreco)"></v-select>
          </v-col>

        </v-row>
        <v-btn class="my-3 mx-3" color="primary" @click="newItem()">Adicionar Item</v-btn>
        <v-btn class="my-3 mx-3" color="success" @click="sincronizarEdicao()">Salvar Edições</v-btn>
        <!-- TABELA DE ITEMS DE PRODUTO -->
        <v-data-table :headers="headers" :items="pedidoDomain.produtos" class="elevation-1" dense>
          <template v-slot:[`item.palets`]="{ item }">
            <span>{{item.qtdPalets.toFixed(2)}}</span>
          </template>
          <template v-slot:[`item.vlritem`]="{ item }">
            <span>{{item.vlrProdutoPedido()}}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon> <v-icon @click="editItem(item)"> mdi-pencil </v-icon></v-btn>
            <v-btn icon color="red"><v-icon @click="removerItem(item)"> mdi-delete </v-icon></v-btn>
          </template>
        </v-data-table>
      
        <v-card class="mt-3 ">
          <v-card-text>
            <v-row class="m-3">
              <v-col cols="12" sm="3">
                <v-text-field dense outlined filled label="Valor Total Pedido"
                  :value="Number.parseFloat(pedidoDomain.totalValor).toLocaleString('pt-br',{style:'currency',currency:'BRL'})" 
                  disabled></v-text-field>
              </v-col >
              <v-col cols="12" sm="3">
                <v-text-field dense outlined filled label="Peso Líquido (KG)" :value="pedidoDomain.totalPesoLiquido" disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field dense outlined filled label="Quantidade Itens" :value="pedidoDomain.totalVolumes" disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field dense outlined filled label="Total Aprox Palets" :value="Number(pedidoDomain.totalPalets).toFixed(2)" disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field dense outlined filled label="Valor Total ST" :value="Number(pedidoDomain.totalST).toLocaleString('pt-br',{style:'currency',currency:'BRL'})" disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" >
                <v-text-field class="text-red" dense outlined filled label="Valor Pedido c/ ST" :value="Number(pedidoDomain.totalST + pedidoDomain.totalValor).toLocaleString('pt-br',{style:'currency',currency:'BRL'})" disabled></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        
      </v-card-text>
    </v-card>
 
    <!-- DIALOG INSERIR PRODUTOS -->
    <div>
      <v-dialog v-model="dialogProdutos" persistent>
        <v-card>

          <div>          
            <v-card-title class="headline primary" style="color:#fff;text-transform:uppercase;">Itens do pedido{{tabelaEspecial == "S" ? " - Tabela Especial": ""}}</v-card-title>

            <v-card>
              <v-card-text>
                <v-row v-if="itemDomain.qtdped && itemDomain.totpalete" align="center" justify="center">
                  <v-col>
                    <h4 >
                      Paletização padrão: <v-chip color="primary">{{itemDomain.totpalete}}</v-chip> 
                    </h4>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col>
                    <h4>
                      Camada: <v-chip color="primary"> {{ itemDomain.qtdlas }} </v-chip>
                    </h4>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col>
                    <h4>
                      Paletização atual: <v-chip :color="Number.parseFloat(itemDomain.qtdped/itemDomain.totpalete).toFixed(2) % 1 > 0 ? 'warning':'success'">{{Number.parseFloat(itemDomain.qtdped/itemDomain.totpalete).toFixed(2)}} </v-chip>
                    </h4>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col>
                    <h4>
                      Sugestão Quantidade (Palet Fechado): <v-chip color="success"> {{Math.ceil(Number.parseFloat(itemDomain.qtdped/itemDomain.totpalete).toFixed(2)) * itemDomain.totpalete}}</v-chip>
                    </h4>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col>
                    <h4>
                      Sugestão Quantidade (Palet Fragmentado): <v-chip :color="Math.ceil(Number.parseFloat(itemDomain.qtdped/itemDomain.qtdlas).toFixed(2)) * itemDomain.qtdlas == itemDomain.qtdped ? 'success':'warning'">{{Math.ceil(Number.parseFloat(itemDomain.qtdped/itemDomain.qtdlas).toFixed(2)) * itemDomain.qtdlas}}</v-chip>
                    </h4>
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>
          </div>
            
          <v-container fluid>

            <v-form @submit="addItemDomain" v-model="valid" ref="formItemPedido">

              <!-- IMAGEM E DADOS PRODUTO -->
              <v-row>
                
                <v-col cols="12" sm="6" md="4" lg="2">
                  <div style="text-align:center;">
                    <img  :src="getImage(itemDomain.codpro)" alt="" style="margin:auto;width:100%;filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));">
                  </div>
                </v-col>

                <v-col>

                  <v-container>
                    
                    <v-row style="margin-bottom:20px;justify-content:center">
                      <v-card-title>{{itemDomain.despro}}</v-card-title>
                    </v-row>

                    <v-row dense>
                      <!-- SELECT INPUT PRODUTO -->
                      <v-col cols="12" md="5">
                        <v-autocomplete dense outlined label="Produto" ref="codproselect"  
                          auto-select-first
                          required
                          v-model="itemDomain.codpro"
                          :items="listaProdutos.map(p => {return {text:`${p.codpro} - ${p.despro}`,value:p.codpro}})"
                          :disabled="item.edicao"
                          @input="removeUltimoItem()"
                          @change="updateItem()"
                          ></v-autocomplete>
                      </v-col>

                      <!-- SELECT INPUT QUANTIDADE -->
                      <v-col cols="12"  :md="pedidoDomain.status=='edicao' ? 2 : 3">
                        <v-text-field dense outlined label="Quantidade" 
                          v-model="itemDomain.qtdped" 
                          min="1" 
                          type="number" 
                          :disabled="!itemDomain.codpro" 
                          :rules="[rules.quantidadeMaiorZero, rules.apenasNumerosInteiros]"
                          @input="pedidoDomain.alterarQuantidadeProduto(itemDomain,itemDomain.qtdped)"
                          @change="validarValores()"
                        ></v-text-field>
                      </v-col>
                      
                      <!-- SELECT INPUT VALOR CALCULADO -->
                      <v-col cols="12" md="3">
                        <div v-if="!itemDomain.ehValorManual">
                          <v-text-field dense outlined filled label="Valor Calculado" 
                            disabled
                            v-model="itemDomain.vlruni" 
                            step="0.01"
                            type="number" 
                            :rules="[() => !tabelaInvalida || 'O Total calculado do item está abaixo do mínimo permitido']"></v-text-field>
                        </div>
                        <div v-if="itemDomain.ehValorManual">
                          <v-text-field dense outlined filled label="Valor Manual" 
                            v-model="itemDomain.valorManual" 
                            step="0.01" 
                            type="number"
                            @change="validarValores()"
                            :rules="[() => !tabelaInvalida || 'O Total calculado do item está abaixo do mínimo permitido',value => value > 0 || 'O Valor do produto deve ser maior do que zero']"></v-text-field>
                        </div>
                      </v-col>
                      <!-- CHECKBOX PARA VALOR MANUAL -->
                      <v-col cols="12" md="2">
                        <v-checkbox 
                          label="Valor Manual?" 
                          v-if="status=='edicao'" 
                          v-model="itemDomain.ehValorManual" 
                          @click="pedidoDomain.alterarQuantidadeProduto(itemDomain,itemDomain.qtdped)" 
                          @input ="validarValores()">Valor Manual</v-checkbox>
                      </v-col>
                    </v-row>

                  </v-container>

                </v-col>


              </v-row>

              <!-- {{ multiploAtual }} -->

              <!-- DESCONTOS (OCULTOS SE É TABELA ESPECIAL)-->
              <v-card elevation="2">
                <v-container v-if="politicaAtiva && !itemDomain.ehValorManual" >
                          
                  <v-row justify="center" align="center" dense>
                    
                    <v-col cols="12" sm="4" md="2" lg="2">
                      <v-text-field dense outlined label="% A Vista"
                        v-if="(condicao == '24' || condicao == '005' || condicao == '0028' || condicao == '003' || condicao == '004') && (tabela != '335' && tabela != '336')"
                        v-model="itemDomain.desavi"
                        type="number"
                        :min="0"
                        :max="politica == 0 ? 2 : politica"
                        :step="politica == 0 ? 2 : politica" 
                        :rules="[rules.totalDescontos,rules.avista]"
                        :hint="`Valores entre 0 e ${politica == 0 ? 2 : politica}`"
                        @change="validarValores()"
                        @input="flagDescontoAplicado = pedidoDomain.aplicarDescontoAvista(itemDomain,itemDomain.desavi)"
                      ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="4" md="2" lg="2">
                        <!-- ADAPTAÇÃO 05/06/2023 -->
                        <!-- <div v-if="produtosSuperPromocionados">
                            <v-text-field dense outlined label="% Política"
                                v-if="pedidoTemDescontoPolitica &&  itemDomain.qtdped > 0 && (cliente.codtpr === ' ' || (cliente.codtpr !== ' ' && politicaAtiva.tipo == 'cliente'))  && (ehCondicaoCorreta || ehTabelaVenda)"
                                v-model="itemDomain.descam"
                                :min="0" 
                                :step="1"
                                type="number" 
                                :disabled="item.valorManual"
                                :max="totalSuperpromocionados >= 3000 ? maximoDescontoPoliticaComputed + 2 : maximoDescontoPoliticaComputed" 
                                :rules="[rules.totalDescontos, rules.politicaSuperPromocionados]"
                                :hint="`Valores entre 0 e ${totalSuperpromocionados >= 3000 ? maximoDescontoPoliticaComputed + 2 : maximoDescontoPoliticaComputed}`"
                                @change="validarValores()"
                                @input="flagDescontoAplicado = pedidoDomain.aplicarDescontoCampanha(itemDomain,itemDomain.descam)"
                            ></v-text-field>
                        </div> -->

                        <div>                        
                            <v-text-field dense outlined label="% Política"
                                v-if="pedidoTemDescontoPolitica &&  itemDomain.qtdped > 0 && (cliente.codtpr === ' ' || (cliente.codtpr !== ' ' && politicaAtiva.tipo == 'cliente'))  && (ehCondicaoCorreta || ehTabelaVenda)"
                                v-model="itemDomain.descam"
                                :min="0" 
                                :step="1"
                                type="number" 
                                :disabled="item.valorManual"
                                :max="maximoDescontoPoliticaComputed" 
                                :rules="[rules.totalDescontos, rules.politica]"
                                :hint="`Valores entre 0 e ${maximoDescontoPoliticaComputed}`"
                                @change="validarValores()"
                                @input="flagDescontoAplicado = pedidoDomain.aplicarDescontoCampanha(itemDomain,itemDomain.descam)"
                            ></v-text-field>
                        </div>
                    </v-col>
  
                    <v-col cols="12" sm="4" md="2" lg="2">
                        <v-text-field dense outlined label="% Extra"
                            v-if="produtoEhSoro && (validaSoroBaldes || validaSoroCaixas || validaLancamento)"
                            v-model="itemDomain.desext"
                            :min="0" 
                            :max="3" 
                            type="number" 
                            step="3"
                            :disabled="item.valorManual"
                            :rules="[rules.totalDescontos,rules.soro]"
                            :hint="`Valores entre 0 e 3`"
                            @change="validarValores()"
                            @input="flagDescontoAplicado = pedidoDomain.aplicarDescontoExtra(itemDomain,itemDomain.desext)"
                        ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="4" md="2" lg="2">
                      <v-text-field dense outlined label="% EXTRA"
                          v-if="new Date() <= new Date(2025,0,1)"
                          v-model="itemDomain.destop"
                          :min="0" 
                          :max="2" 
                          type="number" 
                          step="1"
                          :disabled="item.valorManual"
                          :rules="[rules.totalDescontos, rules.extra]"
                          :hint="`Valores entre 0 e 2`"
                          @change="validarValores()"
                          @input="flagDescontoAplicado = pedidoDomain.aplicarDescontoXTOP(itemDomain,itemDomain.destop)"
                      ></v-text-field>
                  </v-col>
                    <!-- <v-col cols="12" sm="4" md="2" lg="2">
                        <v-text-field dense outlined label="% LANÇAMENTOS"
                            v-if="produtoEhXTOP"
                            v-model="itemDomain.destop"
                            :min="0" 
                            :max="2" 
                            type="number" 
                            step="1"
                            :disabled="item.valorManual"
                            :rules="[rules.totalDescontos, rules.xtop]"
                            :hint="`Valores entre 0 e 2`"
                            @change="validarValores()"
                            @input="flagDescontoAplicado = pedidoDomain.aplicarDescontoXTOP(itemDomain,itemDomain.destop)"
                        ></v-text-field>
                    </v-col> -->

                    <v-col cols="12" sm="4" md="2" lg="2">
                        <v-text-field dense outlined label="% Carga Fechada"
                            v-if="pedidoEhCargaFechada && produtoTemDescontoCargaFechada"
                            v-model="itemDomain.descar"
                            min="0" 
                            type="number" 
                            step="1"
                            :max="pedidoDomain.totalPesoLiquido < 25000 ? descontoMaximoCampanha.cargaFechada : 5" 
                            :disabled="item.valorManual"
                            :rules="[rules.totalDescontos, rules.cargaFechada ]"
                            :hint="`Valores entre 0 e ${pedidoDomain.totalPesoLiquido < 25000 ? descontoMaximoCampanha.cargaFechada : 5}`"
                            @change="validarValores()"
                            @input="flagDescontoAplicado = pedidoDomain.aplicarDescontoCargaFechada(itemDomain,itemDomain.descar)"
                        ></v-text-field>
                    </v-col>
  
                    <v-col cols="12" sm="4" md="2" lg="2">
                        <v-text-field dense outlined label="% Retira na fábrica" 
                            v-if="retiraNaFabrica"
                            v-model="itemDomain.descontoRetiraNaFabrica"
                            min="0" 
                            max="3" 
                            type="number" 
                            step="3"
                            :disabled="item.valorManual"
                            :rules="[rules.totalDescontos,rules.retiraNaFabrica]"
                            :hint="`Valores entre 0 e 3`"
                            @change="validarValores()"
                            @input="flagDescontoAplicado = pedidoDomain.aplicarDescontoRetiraNaFabrica(itemDomain,itemDomain.descontoRetiraNaFabrica)"
                        ></v-text-field>
                        
                        
                        <!-- ADAPTAÇÃO 22/05/2023-->
                        <!-- ## {{ produtoEhSoroCargaFechada }}
                        ## {{ totalSoroCargaFechada }} -->
                        <!-- <v-text-field dense outlined label="% SORO CARGA FECHADA" 
                            v-if="produtoEhSoroCargaFechada && totalSoroCargaFechada >= 10000"
                            v-model="itemDomain.descontoRetiraNaFabrica"
                            min="0" 
                            max="6" 
                            type="number" 
                            step="6"
                            :disabled="item.valorManual"
                            :rules="[rules.totalDescontos,rules.soroCargaFechada]"
                            :hint="`Valores entre 0 e 6`"
                            @change="validarValores()"
                            @input="flagDescontoAplicado = pedidoDomain.aplicarDescontoRetiraNaFabrica(itemDomain,itemDomain.descontoRetiraNaFabrica)"
                        ></v-text-field> -->
                      </v-col>

                  </v-row>
                </v-container>
              </v-card>

            <!-- BOTÕES DE AÇÃO -->
            <v-row style="justify-content:right" class="my-2">
                <v-btn v-if="item.edicao" color="success" class="mx-3" type="submit" >Alterar</v-btn>
                <v-btn v-if="!item.edicao" color="primary" class="mx-3" type="submit" title="alt + i">Ad<u>i</u>cionar</v-btn> 
                <v-btn color="error" class="mx-3" v-if="!item.edicao" @click="cancel()" title="alt + c"><u>C</u>ancelar</v-btn>
            </v-row>

              <!-- CARDS INFORMATIVOS -->
              <v-card class="mt-2">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="3">
                      
                      <v-card v-if="!item.edicao" height="100%">
                          <v-card-title class="text-center">
                            Valor Atual Pedido: 
                          </v-card-title>
                          <v-card-text>
                            <v-divider></v-divider>
                            <h3>{{Number.parseFloat(pedidoDomain.totalValor).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</h3>
                          </v-card-text>
                      </v-card>

                    </v-col>
                    <v-col cols="12" sm="3">
                      
                      <v-card  height="100%" >
                        <v-card-title class="text-center">
                            Valor Unitário com Descontos:
                        </v-card-title>
                        <v-card-text>
                          <v-divider></v-divider>
                          <h3>{{ Number.isNaN(Number.parseFloat(itemDomain.vlrliq)) ? 0 : Number.parseFloat(itemDomain.vlrliq).toLocaleString('pt-br',{style:'currency',currency:'BRL'}) }}</h3>
                        </v-card-text>
                      </v-card>

                    </v-col>

                    <v-col cols="12" sm="3">
                      <v-card  height="100%" >
                          <v-card-title class="text-center">
                              Subtotal Produto: 
                          </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <h3>{{Number.parseFloat(vlrProdutoPedido).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</h3>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="3">
                      <v-card v-if="itemDomain.valorST > 0" color="deep-orange">
                          <v-card-title class="text-center">
                            <h4>Valor ST: {{Number(itemDomain.valorST).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</h4>
                          </v-card-title>
                      </v-card>
                      <v-card >
                          <v-card-title class="text-center">
                            <h4>{{cliente.codtpr == ' ' ? 'Faixa' : ''}} {{cliente.codtpr == ' ' ? pedidoDomain.faixa : ''}}</h4>
                          </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

            </v-form>
          </v-container>
        </v-card>

      </v-dialog>
    </div>

    <!-- DIALOG IMPORTACAO -->
    <div>
      <v-dialog v-model="dialogImportacao" width="750px">
        <v-card>
          <v-card-text>
          <v-card-title>Importando Produtos</v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Pedido</th>
                    <th>Valor</th>
                    <th>Ver Itens</th>
                    <th>Importar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="pedido in pedidosBuscados" :key="pedido._id">
                    <td>{{new Date(pedido.datemi).toLocaleDateString('pt-br')}}</td>
                    <td>{{pedido.numped}}</td>
                    <td>{{Number.parseFloat(valorImportacao(pedido)).toLocaleString('pt-br',{style:'currency',currency:'BRL'})}}</td>
                    <td><v-btn outlined color="info" small @click="verItemsImportados(pedido._id)">Ver</v-btn></td>
                    <td><v-btn color="primary" small @click="efetuarImportacao(pedido._id)">Importar</v-btn></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!-- DIALOG PRODUTOS IMPORTADOS-->
    <div>
      <v-dialog v-model="dialogItensImportados" hide-overlay max-width="500px">
        <v-card>
          <v-card-text>
            <v-simple-table>
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Descrição</th>
                    <th>Quantidade</th>
                    <th>Valor Tabela</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in itensBuscados.itensProduto" :key="item._id">
                    <td>{{item.codpro}}</td>
                    <td>{{item.despro}}</td>
                    <td>{{item.qtdped}}</td>
                    <td>{{Number.parseFloat(item.vlrbru).toLocaleString('pt-br')}}</td>
                  </tr>
                </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {Pedido} from '../../domain/classes/Pedido'
import {Produto} from '../../domain/classes/Produto'
export default {

  props:{
    tabela: String,
    tabelaEspecial: String,
    cliente: Object,
    descontoAvista: Boolean,
    condicao: String,
    politica: Number,
    token: String,
    retiraNaFabrica: Boolean,
    pedidoDomain: Pedido,
    edicaoPedido: Object,
    status: String,
    idProp: String,

  },

  data(){
    return{
      valid:false,
      flagProduto:false,
      flagDescontoAplicado:true,
      faixaPreco:'',
      obsped: '',
      obsnf: '',
      pedrep:'',
      headers:[
        {text:'Cód. Produto',value: 'codpro'},
        {text:'Descrição Produto',value: 'despro'},
        {text:'Quantidade',value: 'qtdped'},
        {text:'Palets',value: 'palets'},
        {text:'Valor Unitario',value: 'vlruni'},
        {text:'% Desconto A Vista',value: 'desavi'},
        {text:'% Desconto Campanha',value: 'descam'},
        {text:'% Desconto Extra',value: 'desext'},
        {text:'% Desconto XTOP',value:'destop'},
        {text:'% Desconto Carga Fechada',value:'descar'},
        {text:'% Desconto Retira na Fábrica',value:'descontoRetiraNaFabrica'},
        {text:'Valor Líq. Item', value: 'vlrliq'},
        {text:'Valor Total Item',value: 'vlritem'},
        {text:'Ações',value:'actions'}
      ],
      headersProdutos:[
        {text:'Código Produto',value:'codpro'},
        {text: 'Descrição Produto',value:'despro'},
        {text: 'Peso Líquido', value:'pesliq'},
        {text: 'Peso Bruto',value:'pesbru'},
      ],
      dialogProdutos:false,
      dialogImportacao:false,
      dialogItensImportados:false,
      item:{edicao:false},
      itemDomain: Produto,
      tabelaInvalida:false,
      pedidosBuscados:[],
      itensBuscados:[],
      politicas:[],
      politicaAtiva:null,
      aplicarDescontoCargaFechada:false,
      perguntarCargaFechada:true,
      regioes:{
          sul:['PR','SC','RS'],
          sudeste: ['ES','RJ','SP','MG'],
          norte:['AM','AC','RO','PA','RR','AP','TO'],
          centroOeste:['MT','MS','GO','DF'],
          nordeste:['MA','PI','CE','RN','PB','PE','AL','SE','BA']
      },
      rules:{
            avista: value => value >= 0 && value <= 2 || 'Desconto invalido',
            totalDescontos: () => this.flagDescontoAplicado || 'Total de descontos não permitido',
            cargaFechada: value => value >= 0 && this.pedidoDomain.totalPesoLiquido < 25000 ? value <= this.descontoMaximoCampanha.cargaFechada : value <= 5 || `O Valor do desconto deve ser entre 0 e ${this.pedidoDomain.totalPesoLiquido < 25000 ? this.descontoMaximoCampanha.cargaFechada : 5}`,
            politica: value => value >= 0 && value <= this.maximoDescontoPoliticaComputed || `O Valor do desconto deve ser entre 0 e ${this.maximoDescontoPoliticaComputed}`,
            soro: value => value >= 0 && value <= 3 || 'O Valor do desconto deve ser entre 0 e 3',
            
            //ADAPTAÇÃO LANÇAMENTOS 01/07/2023
            xtop: value => value >= 0 && value <= 2 || 'O Valor do desconto deve ser entre 0 e 2',
            
            retiraNaFabrica: value => value >= 0 && value <= 3 || 'O Valor do desconto deve ser entre 0 e 3',
            apenasNumerosInteiros: value => Number.isInteger(value*1) || 'Apenas números inteiros',
            quantidadeMaiorZero: value => !!value && value > 0|| 'A Quantidade deve ser maior do que 0',
            
            //ADAPTAÇÃO 25/05/2023 REMOVER EM 31/05
            soroCargaFechada: value => value >= 0 && value <= 6 || 'O Valor do desconto deve ser entre 0 e 4',
        }
    }
  },
  mounted(){
    this.obterPoliticas();
    this.efetuarImportacaoEdicao();
  },
  methods:{
    removeUltimoItem(){
    },
    validarValores(){
      //TRANSFERIR PARA DOMINIO
      let valorMinimo = this.pedidoDomain.tabela.produtos.find(p => p.codpro == this.itemDomain.codpro).vlruni;
      valorMinimo = valorMinimo - (valorMinimo * (this.itemDomain.tolmen/100));

      if(this.itemDomain.vlrliq < valorMinimo){
        this.$set(this,'tabelaInvalida', true);
      }else{
        this.$set(this,'tabelaInvalida', false);
      }

      if(this.produtoEhSoro && (!this.validaSoroBaldes && !this.validaSoroCaixas)){
        this.$set(this.itemDomain,'desext', 0);
      }

      this.$refs.formItemPedido.validate();

    //   this.limpaPoliticaTodosItems();
    },
    updateItem(){
      if(!this.item.edicao){

        if(this.flagProduto){
          this.pedidoDomain.removerProduto(this.pedidoDomain.produtos[this.pedidoDomain.produtos.length - 1]);
        }
        if(this.pedidoDomain.tabela){

          console.log('=>> atualizando item',this.itemDomain.codpro);
          const produtoTabela = this.pedidoDomain.tabela.produtos.find(p => p.codpro == this.itemDomain.codpro);
          const produtoAvulso = new Produto(produtoTabela.codpro,produtoTabela.despro,produtoTabela.vlrori,produtoTabela.pesliq,0,produtoTabela.pesbru);
          this.pedidoDomain.adicionarProduto(produtoAvulso);
          this.itemDomain = this.pedidoDomain.obterProduto(produtoAvulso);
          
          this.flagProduto = true;
        }

      }
    },
    getImage(codpro){
        let img;
        try {
            img = require(`@/assets/produtos/${codpro ? codpro : 'no-image'}.png`);
        } catch (error) {
            img = require(`@/assets/produtos/no-image.png`)
        }
        return img;
    },
    preenchePolitica(){
      if(this.politicaAtiva.produtos){
        this.item.descontoCampanha = this.descontoMaximoCampanha.politica;
        if(this.descontoMaximoCampanha.qtdMinima <= Number.parseFloat(this.item.qtdped) && this.item.qtdped > 0 && this.descontoMaximoCampanha.qtdMinima > 0){
          this.item.descontoExtra = this.descontoMaximoCampanha.percQtdMinima;
        }else{
          this.item.descontoExtra = 0;
        }
        if(this.politicaAtiva.produtos && this.politicaAtiva.qtdCargaFechada <= Number.parseFloat(this.pedidoDomain.totalPalets) + Number.parseFloat(this.item.qtdped/this.item.totpalete)){
          this.item.descontoCargaFechada = this.descontoMaximoCampanha.cargaFechada;
        }else{
          this.item.descontoCargaFechada = 0;
        }
      }
    },

    obterPoliticas(){
        this.$axios.get('/politicas',{headers:{'Authorization':`Bearer ${this.token}`}})
        .then(resp => {
            this.politicas = resp.data;
            this.politicaAtiva = {};

            for (const pol of this.politicas) {
              for (const item of pol.itens) {
                //POLITICA BRASIL
                if(item.item == 'Brasil'){
                  if(this.politicaAtiva.tipo != 'cliente' && this.politicaAtiva.tipo != 'UF' && this.politicaAtiva.item != 'Brasil'){
                    this.politicaAtiva = item;
                  }
                }

                //POLITICA REGIÃO
                else if(item.tipo == 'regiao' && item.item != 'Brasil'){
                  if(this.politicaAtiva.tipo != 'cliente' && this.politicaAtiva.tipo != 'UF' && this.tabelaEspecial != 'S'){
                    let regiao = '';
                    Object.keys(this.regioes).forEach(r => {
                      let reg = this.regioes[r].includes(this.edicaoPedido.sigufs);
                      if(reg){
                        regiao = r;
                      }
                    })
                    if(item.item == regiao){
                      this.politicaAtiva = item;
                    }
                  }
                }

                //POLITICA UF
                else if(item.tipo=='UF'){
                  if(item.item == this.edicaoPedido.sigufs){
                    if(this.politicaAtiva.tipo != 'cliente' && this.tabelaEspecial != 'S'){
                      this.politicaAtiva = item;
                    }
                  }
                }
                
                //POLITICA CLIENTE
                else if(item.tipo=='cliente'){
                  if(item.item.split(' - ')[0] == this.edicaoPedido.codcli){
                    this.politicaAtiva = item;
                  }
                }

              }
            }

            if(Object.keys(this.politicaAtiva).length === 0){
              this.politicaAtiva = null;
            }
        })
        .catch(err =>{
            console.log(err);
            alert('Não foi possível obter as polítias de preço')
        })
    },

    newItem(){
      this.itemDomain = new Produto(0,'',0,0,0,0);
      this.dialogProdutos = true;  
    },

    verItemsImportados(id){
      this.dialogItensImportados = true;
      if(this.pedidosBuscados){
        this.itensBuscados = this.pedidosBuscados.find(p => p._id == id);
      }
    },


    efetuarImportacaoEdicao(){
      // let pedido = this.pedidosBuscados.find(p => p._id == id);
      
      this.obsped = this.edicaoPedido.obsped;
      this.obsnf = this.edicaoPedido.obsnf;
      this.pedrep = this.edicaoPedido.pedrep;

      if(this.edicaoPedido.status == 'edicao'){
        this.pedidoDomain.transformarPedidoParaAprovacao();
        this.pedidoDomain.alterarFaixaPreco(this.edicaoPedido.faixa);
        this.faixaPreco = this.edicaoPedido.faixa;  
      }



      for (const i of this.edicaoPedido.itensProduto) {
        // this.itemDomain.qtdped = i.qtdped;
        
        this.itemDomain.codpro = i.codpro;

        this.updateItem();

        //ATUALIZAÇÃO FEITA EM 27/10/2023 => 
        if(i.valorManual > 0){
          const itempedido = this.pedidoDomain.produtos.find(p => p.codpro == i.codpro);
          if(itempedido){
            itempedido.ehValorManual = true;
            itempedido._ehValorManual = true;
            itempedido.valorManual = i.valorManual;
          }
        }
        
        this.pedidoDomain.alterarQuantidadeProduto(this.itemDomain,i.qtdped)
        this.pedidoDomain.aplicarDescontoAvista(this.itemDomain,i.desavi);
        this.pedidoDomain.aplicarDescontoCampanha(this.itemDomain,i.descam);
        this.pedidoDomain.aplicarDescontoExtra(this.itemDomain,i.desext);
        this.pedidoDomain.aplicarDescontoRetiraNaFabrica(this.itemDomain,i.retfab);
        this.pedidoDomain.aplicarDescontoCargaFechada(this.itemDomain,i.descar);
        this.pedidoDomain.aplicarDescontoXTOP(this.itemDomain,i.destop);
        // this.itemDomain.desavi = i.desavi;
        // this.itemDomain.descam = i.descam;
        // this.itemDomain.desext = i.desext;
        this.addItemDomain(this.$event,true)
      }
      this.dialogImportacao = false;
    },
    efetuarImportacao(id){
      let pedido = this.pedidosBuscados.find(p => p._id == id);
      for (const i of pedido.itensProduto) {
        this.itemDomain.codpro = i.codpro;
        this.updateItem();
        this.pedidoDomain.alterarQuantidadeProduto(this.itemDomain,i.qtdped)
        this.addItemDomain(this.$event,true)
      }
      this.dialogImportacao = false;
    },

    addItemDomain(evt,importacao=false){
      
      if(evt){
        evt.preventDefault();
      }
      if(!importacao){
        if(!this.$refs.formItemPedido.validate()){
          console.log(this.$refs.formItemPedido.validate())
          return alert('Verifique os campos em vermelho antes de prosseguir');
        }
      }
      //ADICIONA DESCONTO DE CARGA FECHADA SE O PEDIDO ALCANÇAR A QUANTIDADE DE PALETES//
      if(this.politicaAtiva){

        if(this.pedidoEhCargaFechada){

          if(this.perguntarCargaFechada){
            this.aplicarDescontoCargaFechada = confirm('O Pedido atingiu o peso mínimo da política de pesos para carga fechada, deseja aplicar o desconto para todos os itens?');
            if(this.aplicarDescontoCargaFechada){
              for(const produtoDomain of this.pedidoDomain.produtos){
                let CF = this.politicaAtiva.produtos.find(p => p.codpro == produtoDomain.codpro);
                if(CF){
                  this.pedidoDomain.aplicarDescontoCargaFechada(produtoDomain,CF.cargaFechada)
                }
              }
            }else{
              this.perguntarCargaFechada = false;
            }
            this.perguntarCargaFechada = false;
          }
        }else{
          // for(const produtoDomain of this.pedidoDomain.produtos){
          //     this.pedidoDomain.aplicarDescontoCargaFechada(produtoDomain,0);
          // }
        }
      }

      this.item.edicao = false;
      this.dialogProdutos = false;
      this.flagProduto = false;
      this.$emit('lockPedido',true);
    },

    cancel(){
      if(this.item.edicao){
        if(this.itemDomain.qtdped == 0 || !this.itemDomain.qtdped){
          alert('Digite uma quantidade válida')
        }
      }
      this.pedidoDomain.removerProduto(this.itemDomain);
      this.flagProduto = false;
      this.dialogProdutos = false;

      if(this.pedidoDomain.produtos.length == 0){
        this.$emit('lockPedido',false);
      }
     
    },

    editItem(item){
      this.itemDomain = item;
      this.flagProduto = false;
      this.item.edicao = true;
      this.dialogProdutos = true;
    },
   
    removerItem(item){
      this.pedidoDomain.removerProduto(item);
      
      this.limpaPoliticaTodosItems();
      
      if(this.pedidoDomain.produtos.length == 0){
        this.$emit('lockPedido',false);
      }
    },

    valorImportacao(pedido){
      if(pedido){

        let valor = 0;
        for (const i of pedido.itensProduto) {
          valor += i.qtdped * i.vlrliq;
        }
        return valor;
      }else{
        return 0;
      }
    },
    limpaPoliticaTodosItems(){
        // if(this.totalLancamentosXtop < 2 && this.pedidoDomain.status=='digitado'){
        //   if(this.totalLancamentosXtop < 2){
        // if(this.totalLancamentosXtop < 10){
        //     for(const produto of this.pedidoDomain.produtos){
        //         produto.destop = 0;
        //     }
        // }
        // if(this.totalSuperpromocionados < 3000){
        //     for (const produto of this.pedidoDomain.produtos) {
        //         const maximo = this.obterMaximoPoliticaPorProduto(produto.codpro);
        //         if(produto.descam > 0 && produto.descam > maximo.politica){
        //         produto.descam = maximo.politica;
        //         }
        //         // produto.descam = 0;
        //     }
        // }
        // for (const produto of this.pedidoDomain.produtos) {
        //     const multiploAtual = this.listaMultiplos.find(m => m.produtos.includes(produto.codpro));
        //     const totalMultiplos = this.pedidoDomain.produtos.filter(x => multiploAtual?.produtos.includes(x.codpro)).reduce((a,b) => a + (b.qtdped*1), 0);
        //     if(totalMultiplos < multiploAtual?.qtd){
        //         const maximo = this.obterMaximoPoliticaPorProduto(produto.codpro)
        //         produto.descam = maximo.politica;
        //     }
        // }
    },
    obterMaximoPoliticaPorProduto(codpro){
        // const multiploAtual = this.listaMultiplos.find(m => m.produtos.includes(codpro));
        // const totalMultiplos = this.pedidoDomain.produtos.filter(x => multiploAtual?.produtos.includes(x.codpro)).reduce((a,b) => a + (b.qtdped*1), 0);
        const maximos = {};

        maximos.politica = 0;
        maximos.percQtdMinima = 0;
        maximos.qtdMinima = 0;
        maximos.qtdCargaFechada = 0;
        maximos.cargaFechada = 0;
        if(this.politicaAtiva){
            if(this.politicaAtiva.produtos.length > 0){
            let produtoPolitica = this.politicaAtiva.produtos.find(p => p.codpro == codpro);
            if(produtoPolitica){
                maximos.politica = Number(produtoPolitica.politica);// + (totalMultiplos >= multiploAtual?.qtd ? 2 : 0);
                maximos.percQtdMinima = produtoPolitica.percQtdMinima;
                maximos.qtdMinima = produtoPolitica.qtdMinima;
                maximos.qtdCargaFechada = this.politicaAtiva.qtdCargaFechada;
                maximos.cargaFechada = produtoPolitica.cargaFechada;
            }
            }
        }  
        return  maximos;
    },
    sincronizarEdicao(){
      if(this.status == 'digitado' && this.valorTotalPedido < 1100 && (this.codrep != 6 && this.codrep != 4) && this.tnspro == '90100'){
         alert('Pedido de venda abaixo de R$ 1.100,00 \n Enquadre o pedido nos valores mínimos antes de continuar');
      }else{
        let itemsEscolhidos = this.pedidoDomain.produtos;
        let itemsMod = itemsEscolhidos.map(p => {
          return{
            codpro: p.codpro,
            qtdped: p.qtdped,
            desavi: p.desavi,
            descam: p.descam,
            desext: p.desext,
            descar: p.descar,
            destop: p.destop,
            retfab: p.descontoRetiraNaFabrica,
            vlruni: p.vlruni,
            vlrliq: p.vlrliq,
            valorManual: p.valorManual,
            valorST: p.valorST,
            codtpr: this.pedidoDomain.tabela.codtpr
          }
        })
  
        this.$axios.post('/pedidos/editar',{items:itemsMod, id:this.idProp, obsnf:this.obsnf, obsped:this.obsped,pedrep:this.pedrep},{headers:{'Authorization':`Bearer ${sessionStorage.getItem('token')}`}})
        .then((resp) => {
          console.log(resp)
          alert("Pedido Alterado com sucesso!");
          this.$emit('refresh');
          this.$emit('fecharEdicao');
        })
        .catch(err => {
          alert('Erro ao editar pedido')
          console.error(err);
        })
      }
    }
  },

  computed:{
    listaMultiplos(){
        const campanhaMultiplos = [
            {
                nome: '400g',
                produtos: ['641','642','643','522','523','802','604','803','804','805'],
                qtd: 20
            },
            {
                nome: '1kg',
                produtos: ['525','526'],
                qtd: 20
            },
            {
                nome: '2kg',
                produtos: ['715','712','607','606','609','1071','605','630','1070','608','631'],
                qtd: 25
            },
            {
                nome: 'tabletes',
                produtos: ['702','703'],
                qtd: 30
            },
            {
                nome: 'bisnaga',
                produtos: ['1046','950','981','980','974','949','756','755','1043','1073','1072'],
                qtd: 30
            },
            {
                nome: 'balde 9,8',
                produtos: ['601','600','721','720','723','635','634','10','767'],
                qtd: 480
            },
            {
                nome: 'balde 4,8',
                produtos: ['773','774','1065','602','603'],
                qtd: 216
            },
            {
                nome: 'caixeta',
                produtos: ['20','21','22','23'],
                qtd: 25
            },
            {
                nome: 'latas',
                produtos: ['626','627','628'],
                qtd: 150
            },
        ]
        return campanhaMultiplos;
    },
    multiploAtual(){
        const multiploProduto = this.listaMultiplos.find(m => m.produtos.includes(this.itemDomain.codpro));
        return multiploProduto
    },
    totalMultiplos(){
        // const multiplos = this.multiploAtual;
        // if(this.pedidoDomain.produtos && multiplos){
        //     const qtdMultiplos = this.pedidoDomain.produtos.filter(x => multiplos.produtos.includes(x.codpro)).reduce((a,b) => a + (b.qtdped*1), 0);
        //     return qtdMultiplos;
        // }else{
        //     return 0;
        // }
        return 0;
    },
    listaProdutos(){
      if(this.pedidoDomain.produtos){
        return this.pedidoDomain.tabela.produtos.filter(x => !this.pedidoDomain.produtos.some(produto => produto.codpro == x.codpro) || x.codpro == this.itemDomain.codpro)
      }else{
        return [];
      }
    },
    vlrProdutoPedido(){
      try {
        return this.itemDomain.vlrProdutoPedido();
        
      } catch (error) {
        return 0;
      }
    },
    //ADAPTAÇÃO 25/05/2023
    produtoEhSoroCargaFechada(){
      const listaSoro = ['600','601','602','603'];
      return listaSoro.includes(this.itemDomain.codpro);
    },
    totalSoroCargaFechada(){
      const lancamentos = ['600','601','602','603'];
      if(this.pedidoDomain.produtos){
        const qtdLancamentos = this.pedidoDomain.produtos.filter(x => lancamentos.includes(x.codpro)).reduce((a,b) => a + (b.pesliq*1)*(b.qtdped*1), 0);
        return qtdLancamentos;
      }else{
        return 0;
      }
      
    },
    //FIM ADAPTAÇÃO 25/05/2023

    //ADAPTAÇÃO 05/06/2023
    // produtosSuperPromocionados(){
    //   const promocionados = ['1028','1029','1005','522','523','525','526','630','631','641','642','643','980','981','982'];
    //   return promocionados.includes(this.itemDomain.codpro);
    // // },
    // totalSuperpromocionados(){
    //     const promocionados = ['1028','1029','1005','522','523','525','526','630','631','641','642','643','980','981','982'];
    //     if(this.pedidoDomain.produtos){
    //         const qtdPromocionados = this.pedidoDomain.produtos.filter(x => promocionados.includes(x.codpro)).reduce((a,b) => a + ((b.qtdped*1)*b.pesliq), 0);
    //         return qtdPromocionados;
    //     }else{
    //         return 0;
    //     }
    // },
    //FIM ADAPTAÇÃO 05/06/2023

    produtoEhSoro(){
      const listaSoro = ['600','601','602','603', '1070','1071','1072','1073','604','605'];
      return listaSoro.includes(this.itemDomain.codpro);
    },
    produtoEhXTOP(){
        //   const listaXPRO = ['802','803','804','805','1070','1071','1072','1073','604','605'];
        // ALTERADO NO LANÇAMENTO DOS PRODUTOS 01/07/2023
        const listaXPRO = ['606','607','608','609'];
      return listaXPRO.includes(this.itemDomain.codpro) && (this.totalLancamentosXtop >= 10)// && (this.cliente.qtdPedidosXtop <= 10);
    },
    pedidoEhCargaFechada(){
      return this.politicaAtiva ? this.politicaAtiva.qtdCargaFechada <= Number.parseFloat(this.pedidoDomain.totalPesoLiquido)  : false;
    },
    pedidoTemDescontoPolitica(){
    //   return this.descontoMaximoCampanha.politica > 0
        return this.maximoDescontoPoliticaComputed > 0
    },
    produtoTemDescontoCargaFechada(){
      const produtoNaPolitica = this.politicaAtiva.produtos.find(p => p.codpro == this.itemDomain.codpro);
      if(produtoNaPolitica){
        return produtoNaPolitica.cargaFechada > 0;
      }else{
        return false;
      }
    },
    validaSoroBaldes(){
      return this.totalSoro >= 320  && (this.itemDomain.codpro == 600 || this.itemDomain.codpro == 601)
    },
    validaSoroCaixas(){
      return this.totalSorinho >= 145 && (this.itemDomain.codpro == 602 || this.itemDomain.codpro == 603)
    },
      validaLancamento(){
      // ALTERADO NA CAMPANHA DA SEGUNDA QUINZENA DE AGOSTO
      // return this.totalSorinho >= 125 && (this.itemDomain.codpro == 602 || this.itemDomain.codpro == 603)
      // return this.totalSorinho >= 145 && (this.itemDomain.codpro == 602 || this.itemDomain.codpro == 603)
      
      //ALTERADO NA CAMPANHA DE NOVEMBRO
      // switch (this.itemDomain.codpro) {
      //   case '1070':
      //     return this.itemDomain.qtdped >= 5;
      
      //   case '1071':
      //     return this.itemDomain.qtdped >= 5;
      
      //   case '604':
      //     return this.itemDomain.qtdped >= 5;
      
      //   case '605':
      //     return this.itemDomain.qtdped >= 5;
      
      //   case '1072':
      //     return this.itemDomain.qtdped >= 10;
      
      //   case '1073':
      //     return this.itemDomain.qtdped >= 10;
      
      //   default:
      //     return false;
      // }
      return false;
    },
    ehTabelaVenda(){
      return (this.tabela != '335' && this.tabela != '336')
    },
    ehCondicaoCorreta(){
      return this.condicao == '24' || this.condicao == '005' || this.condicao == '0028' || this.condicao == '003' || this.condicao == '004'
    },

    totalSoro(){
      let soro = 0;
      for(const p of this.pedidoDomain.produtos){
        if(p.codpro == 600 || p.codpro == 601){
          soro += Number.parseInt(p.qtdped);
        }
      }

      if(!this.item.edicao){
        if(this.itemDomain.codpro == 600 || this.itemDomain.codpro == 601){
          if(this.item.qtdped > 0){
            soro += Number.parseInt(this.item.qtdped);
          }
        }
      }
      return soro;
    },

    totalLancamentosXtop(){
    //   const lancamentos = ['604','605','1070','1071','1072','1073','802','803','804','805'];
      const lancamentos = ['606','607','608','609'];
      if(this.pedidoDomain.produtos){
        const qtdLancamentos = this.pedidoDomain.produtos.filter(x => lancamentos.includes(x.codpro)).reduce((a,b) => a + (b.qtdped*1), 0);
        return qtdLancamentos;
      }else{
        return 0;
      }
      
    },

    totalSorinho(){
      let soro = 0;

      for(const p of this.pedidoDomain.produtos){
        if(p.codpro == 602 || p.codpro == 603){
          soro += Number.parseInt(p.qtdped);
        }
      }

      if(!this.item.edicao){
        if(this.itemDomain.codpro == 602 || this.itemDomain.codpro == 603){
          if(this.item.qtdped > 0){
            soro += Number.parseInt(this.item.qtdped);
          }
        }
      }
      return soro;
    },

    descontoMaximoCampanha(){
      let maximos = {};
      maximos.politica = 0;
      maximos.percQtdMinima = 0;
      maximos.qtdMinima = 0;
      maximos.qtdCargaFechada = 0;
      maximos.cargaFechada = 0;
      if(this.politicaAtiva){
        if(this.politicaAtiva.produtos.length > 0){
          let produtoPolitica = this.politicaAtiva.produtos.find(p => p.codpro == this.itemDomain.codpro);
          if(produtoPolitica){
            maximos.politica = produtoPolitica.politica;
            maximos.percQtdMinima = produtoPolitica.percQtdMinima;
            maximos.qtdMinima = produtoPolitica.qtdMinima;
            maximos.qtdCargaFechada = this.politicaAtiva.qtdCargaFechada;
            maximos.cargaFechada = produtoPolitica.cargaFechada;
          }
        }
      }  
      return  maximos;
    },

    maximoDescontoPoliticaComputed(){

        if(this.tabelaEspecial != 'S'){

            // if(this.multiploAtual && this.totalMultiplos >= this.multiploAtual.qtd){
            //     return this.descontoMaximoCampanha.politica + 2;
            // }

            return this.descontoMaximoCampanha.politica;

        }else if(this.tabelaEspecial == 'S' && this.politicaAtiva.tipo == 'cliente'){

            // if(this.multiploAtual&& this.totalMultiplos >= this.multiploAtual.qtd){
            //     return this.descontoMaximoCampanha.politica + 2;
            // }

            return this.descontoMaximoCampanha.politica;
        }else{
            return 0;
        }
    }
}
}

</script>

<style>

</style>