import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AtividadeView from '../views/AtividadeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/atividade',
    name:'AtividadeView',
    component: AtividadeView
  },
  {
    path:'/atividades',
    name:'atividades',
    component: () => import('../views/ConsultaAtividades.vue')
  },
  {
    path:'/agenda',
    name:'agenda',
    component: () => import('../views/AgendaView.vue')
  },
  {
    path:'/login',
    name:'login',
    component: () => import('../components/LoginComponent.vue')
  },
  {
    path:'/clientes',
    name:'clientes',
    component: () => import('../components/clientes/ConsultaClientes.vue')
  },
  {
    path:'/pedido',
    name:'pedido',
    component: () => import('../views/PedidosView.vue')
  },
  {
    path:'/pedido-exportacao',
    name:'pedido-export',
    component: () => import('../components/pedidos/exportacao/AdicionarPedidoExportacao.vue')
  },
  // AINDA NÃO LIBERAR ESTA ROTA
  {
    path:'/orcamento',
    name:'orcamento',
    component: () => import('../components/orcamentos/AdicionarOrcamento.vue')
  },
  {
    path:'/orcamentos',
    name:'orcamentos',
    component: () => import('../components/orcamentos/Consulta_Orcamentos.vue')
  },
  {
    path:'/orcamentos/imprimir',
    name:'imprimir orcamentos',
    component: () => import('../components/orcamentos/Imprime_Orcamento.vue')
  },
  {
    path:'/pedido-aprovacao',
    name:'pedidoaprovacao',
    component: () => import('../views/PedidosView.vue'),
    
  },
  {
    path:'/pre-pedidos',
    name:'pre-pedido',
    component: () => import('../views/ConsultaPrePedidos.vue')
  },
  {
    path:'/pre-pedidos-marcos',
    name:'pre-pedido-marcos',
    component: () => import('../components/pedidos/ConsultarPrePedidosMarcos.vue')
  },
  {
    path:'/pre-pedidos/imprimir',
    name:'impressao',
    component: () => import('../components/pedidos/Imprime_Pedido.vue')
  },
  {
    path:'/liberar-pedidos',
    name:'liberar-pedidos',
    component: () => import('../views/LiberaPrePedidos.vue')
  },
  {
    path:'/pedidos',
    name:'pedidos',
    component: () => import('../views/ConsultaPedido.vue')
  },
  {
    path:'/dados-logisticos',
    name:'dados-logisticos',
    component: () => import('../views/ConsultaDadosLogisticos.vue')
  },
  {
    path:'/dados-logisticos/imprimir',
    name:'dados-logisticos-imprimir',
    component: () => import('../components/produtos/Imprime_DadosLogisticos.vue')
  },
  {
    path:'/tabela-preco',
    name:'tabela-preco',
    component: () => import('../views/TabelaPreco.vue')
  },
  {
    path:'/usuarios',
    name:'usuarios',
    component: () => import('../views/UsuariosView.vue')
  },  
  {
    path:'/cadastro-clientes',
    name:'cadastroClientes',
    component: () => import('../components/clientes/CadastroClientes.vue')
  },
  {
    path:'/cadastros/:idCadastro',
    name:'Ver Novo Cadastro',
    component: () => import('../components/clientes/VerNovoCadastro.vue')
  },
  {
    path:'/clientes-cadastrados',
    name:'clientesCadastrados',
    component: () => import('../views/ClientesCadastradosView.vue')
  },  
  {
    path:'/imprimir-cadastro-clientes',
    name:'imprimirCadastroClientes',
    component: () => import('../components/clientes/ImprimeCadastroCliente.vue')
  },
  {
    path:'/politicas',
    name:'politicas',
    component: () => import('../components/clientes/PoliticasComponent.vue')
  },
  {
    path:'/fretes',
    name:'fretes',
    component: () => import('../views/FretesView.vue')
  },
  {
    path:'/cargas',
    name:'cargas',
    component: () => import('../components/fretes/CargasCriadas.vue')
  },
  {
    path:'/maiores-quedas',
    name:'maiores-quedas',
    component: () => import('../components/relatorios/MaioresQuedas.vue')
  },
  {
    path:'/motoristas',
    name:'motoristas',
    component: () => import('../views/CadastroMotoristaView.vue')
  },
  {
    path:'/consultar-motoristas',
    name:'consultar-motoristas',
    component: () => import('../components/motoristas/ConsultaMotoristasComponent.vue')
  },
  {
    path:'/crm',
    name:'crm',
    // component: () => import('@/components/crm/CadastrosCanaisCampanhas.vue')
    component: () => import('@/views/CRMView.vue')
  },
  {
    path:'/relatorio-detalhado',
    name:'relatorio-detalhado',
    component: () => import('@/components/relatorios/FaturadoDetalhado.vue')
  },
  {
    path:'/campanha-fim-ano-24',
    name:'campanha-fim-ano-24',
    component: () => import('../components/relatorios/CampanhaFimAno24.vue')
  },
  // {
  //   path:'/campanha-maio',
  //   name:'campanha-maio',
  //   component: () => import('../components/relatorios/CampanhaComponent.vue')
  // },
  {
    path:'/campanha-julho',
    name:'campanha-julho',
    component: () => import('../components/relatorios/CampanhaJulhoComponent.vue')
  },
  // {
  //   path:'/campanha-pascoa',
  //   name:'campanha',
  //   component: () => import('../components/relatorios/CampanhaComponent.vue')
  // },
  {
    path:'/agenda-crm',
    name:'agenda-crm',
    component: () => import('@/components/crm/AgendaCRMComponent.vue')
  }
  // {
  //   path:'/rastreabilidade',
  //   name:'consultar-rastreabilidade',
  //   component: () => import('../components/relatorios/RastreabilidadeComponent.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'Pedidos Xamego Bom';
  const token = sessionStorage.getItem('token');
  if(token){
    next();
  }else{
    if(to.path == '/login' || to.path == '/cadastro-clientes' || to.name == 'Ver Novo Cadastro'){
      next();
    }else{
      next({
        path:'/login',
        replace:true
      })
    }
  }
})

export default router
