<template>
  <div>
    <div class="mb-3">
      <b-navbar toggleable="xl" type="dark" variant="dark">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav v-if="usuario.username != 'marcos'">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Início</router-link>
            </li>
            
            <b-nav-item-dropdown text="Atividades 📝 " v-if="token && usuario.representacao != 'colabora' && usuario.representacao != 'temporario'">
              <b-dropdown-item to="/atividade" v-if="usuario.role != 'representante' "> Adicionar Atividades ➕</b-dropdown-item>
              <!-- <b-dropdown-item to="/agenda"> Agenda 📆</b-dropdown-item> -->
              <hr>
              <b-dropdown-item to="/atividades"> Consultar Atividades 🔎</b-dropdown-item>
              <hr>
              <!-- <b-dropdown-item to="/campanha-maio"> Consultar Campanha Maio 💵 <v-icon color="error">mdi-close</v-icon></b-dropdown-item> -->
              <!-- <b-dropdown-item to="/campanha-julho"> Consultar Campanha Julho 💵 <v-icon color="error">mdi-alert-decagram</v-icon></b-dropdown-item> -->
              <b-dropdown-item to="/campanha-fim-ano-24"> Consultar Campanha Fim de Ano 💵 <v-icon color="error">mdi-alert-decagram</v-icon></b-dropdown-item>
            </b-nav-item-dropdown>
            
            <b-nav-item-dropdown text="Pedidos 📦" v-if="token && usuario.representacao != 'colabora'">
              <b-dropdown-item to="/pedido">Adicionar Pré-Pedido <v-icon light>mdi-plus</v-icon></b-dropdown-item>
              <!-- <b-dropdown-item to="/pedido-exportacao">Adicionar Pré-Pedido EXPORTAÇÃO <v-icon light>mdi-plus</v-icon></b-dropdown-item> -->
              <!-- <b-dropdown-item to="/pedido-aprovacao">Adicionar Pré-Pedido P/ Aprovação ➕</b-dropdown-item> -->
              <hr>
              <b-dropdown-item to="/pre-pedidos">Consultar Pré-Pedidos <v-icon light>mdi-magnify</v-icon></b-dropdown-item>
              <b-dropdown-item to="/pedidos">Consultar Pedidos <v-icon light>mdi-magnify</v-icon></b-dropdown-item>
              <hr>
              <b-dropdown-item to="/clientes">Consultar Clientes <v-icon light>mdi-account-search</v-icon></b-dropdown-item>
              <hr>
              <b-dropdown-item to="/orcamento">Criar Orçamento </b-dropdown-item>
              <b-dropdown-item to="/orcamentos">Consultar Orçamentos </b-dropdown-item>
              <hr>
              <b-dropdown-item to="/relatorio-detalhado">Relatório Detalhado <v-icon color="error">mdi-alert-decagram</v-icon></b-dropdown-item>
              <hr>
              <b-dropdown-item to="/liberar-pedidos" v-show="usuario.role == 'master' || usuario.role == 'supervisor' || usuario.codrep == '69' || usuario.codrep == '110'">Liberar Pedidos <v-icon light>mdi-lock-open-check</v-icon></b-dropdown-item>
              <!-- <b-dropdown-item to="/tarefas" v-show="usuario.role == 'master' || usuario.role == 'televendas'">Tarefas <v-icon light>mdi-calendar-check</v-icon></b-dropdown-item> -->
            </b-nav-item-dropdown>
            
            <li class="nav-item">
              <router-link to="/crm" class="nav-link" v-show="(usuario.role == 'master' && usuario.codrep != 110) || usuario.role == 'televendas' || usuario.role == 'vendedor' || usuario.role == 'supervisor'">CRM <v-icon dark>mdi-calendar-check</v-icon></router-link>
            </li>
            <li class="nav-item" v-if="token">
              <router-link to="/agenda-crm" class="nav-link">Agenda <v-icon dark>mdi-calendar-cursor</v-icon></router-link>
            </li>
            
            
            <b-nav-item-dropdown text="Dados Logísticos 🚛" v-if="token && usuario.representacao != 'colabora' && usuario.representacao != 'temporario'">
              <b-dropdown-item to="/dados-logisticos" v-if="token">Consultar Dados Logísticos <v-icon light>mdi-magnify</v-icon></b-dropdown-item>
            </b-nav-item-dropdown>
            
            <b-nav-item-dropdown text="Tabelas de Preço 📋" v-if="token && usuario.representacao != 'colabora' && usuario.representacao != 'temporario'">
              <b-dropdown-item to="/tabela-preco" v-if="token">Consultar tabelas <v-icon light>mdi-magnify</v-icon></b-dropdown-item>
            </b-nav-item-dropdown>
            
            <v-badge overlap color="error" :content="qtdNovosClientes" :value="qtdNovosClientes" v-if="usuario.representacao != 'colabora' && usuario.representacao != 'temporario'">
              
              <b-nav-item-dropdown text="Cadastros de Clientes 📋" v-if="token && usuario.representacao != 'colabora' && usuario.representacao != 'temporario'">
                <a role="button" class="nav-link btn btn-primary m-1 btn-sm" @click="obterLinkCliente()">Obter link para cadastro de cliente</a>
                <b-dropdown-item to="/clientes-cadastrados" v-if="token">Consultar cadastros <v-icon light>mdi-magnify</v-icon></b-dropdown-item>
              </b-nav-item-dropdown>
            </v-badge>
            
            <b-nav-item-dropdown text="Admin 📌" v-if="token" v-show="usuario.role == 'master' && usuario.representacao != 'colabora' && usuario.representacao != 'temporario'" >
              <b-dropdown-item to="/fretes" v-show="usuario.role == 'master'" v-if="token">Fretes 🚛</b-dropdown-item>
              <b-dropdown-item to="/cargas" v-show="usuario.role == 'master'" v-if="token">Ver Cargas 🚛</b-dropdown-item>
              <b-dropdown-item to="/motoristas" v-show="usuario.role == 'master'" v-if="token">Importar Motorista 🚛</b-dropdown-item>
              <b-dropdown-item to="/consultar-motoristas" v-show="usuario.role == 'master'" v-if="token">Consultar Motoristas Importados 🚛</b-dropdown-item>
              <v-divider></v-divider>
              <b-dropdown-item to="/usuarios" v-show="usuario.role == 'master'" v-if="token">Usuarios 📌</b-dropdown-item>
              <b-dropdown-item to="/politicas" v-show="usuario.role == 'master'" v-if="token">Políticas de Preço 📌</b-dropdown-item>
              <b-dropdown-item to="/maiores-quedas" v-show="usuario.role == 'master'" v-if="token">Relatório maiores quedas 📝</b-dropdown-item>
            </b-nav-item-dropdown>
            
            <!-- <b-nav-item-dropdown text="Alterar Senha" v-if="token"> -->
              <!-- <AlterarSenha /> -->
            <!-- </b-nav-item-dropdown> -->
            <b-nav-item-dropdown text="Downloads" v-if="token">
              <b-dropdown-item href="/files/CATALOGO_XAMEGO_BOM.pdf" target="_blank" download>Catálogo Virtual - XAMEGO BOM</b-dropdown-item>
              <b-dropdown-item href="/files/CATALOGO_ITAPERUNA.pdf" target="_blank" rel="noopener noreferrer" download>Catálogo Virtual - ITAPERUNA</b-dropdown-item>
              <b-dropdown-item href="/files/APRESENTACAO_XAMEGO_BOM.pdf" target="_blank" rel="noopener noreferrer" download>Apresentação Produtos</b-dropdown-item>
              <b-dropdown-item href="/files/APRESENTACAO_ITAPERUNA.pdf" target="_blank" rel="noopener noreferrer" download>Apresentação Produtos Itaperuna</b-dropdown-item>
              <b-dropdown-item href="/files/FICHA_CADASTRAL_CLIENTES.xlsx" target="_blank" rel="noopener noreferrer" download>Ficha de Cadastro Clientes</b-dropdown-item>
            </b-nav-item-dropdown>
            
            <v-badge overlap :color="avisos.filter(a => !a.visto).length > 0 ? 'error' : ''" v-if="token" :content="avisos.filter(a => !a.visto).length" :value="avisos.length">
              <b-nav-item-dropdown dropleft text="Avisos" v-if="token">
                <v-card width="380px">
                  <v-card-title>Avisos</v-card-title>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Visto</th>
                        <th>Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="aviso in avisos" :key="aviso.nome">
                        <td>{{ aviso.aviso.nome }}</td>
                        <td>{{ aviso.visto ? '✔️' : '❌' }}</td>
                        <td>
                          <v-btn color="primary" small @click="marcarAvisoLido(aviso)">ver</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </b-nav-item-dropdown>
            </v-badge>

            <b-nav-item v-if="usuario.representacao" disabled>{{usuario.representacao}}</b-nav-item>
          </b-navbar-nav>
          
          <b-navbar-nav class="ml-auto">
            <li class="nav-item" v-if="token">
              <v-btn small fab color="white" @click="changeTheme()" class="mt-1" icon><v-icon>mdi-theme-light-dark</v-icon></v-btn>
            </li>
            <li class="nav-item" v-if="token">
              <a role="button" class="nav-link btn btn-outline-danger m-1 btn-sm" @click="logout()">Sair</a>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    
    <v-dialog v-model="dialogLink" max-width="1050px">
      <v-card class="pt-3">
        <v-card-text>
          
          <h3 class="mt-3">Envie este link para seu cliente</h3>
          <v-textarea outlined dense label="LINK" class="m-3" v-model="linkCliente"></v-textarea>
          <v-row class="justify-content-center">
            <v-btn :href="`whatsapp://send?text=${linkCliente}`" color="success">Compartilhar no Whatsapp <v-icon right>mdi-whatsapp</v-icon></v-btn>
            <v-btn class="ml-2" :href="`mailto://seu@cliente.com?subject=${encodeURI('Cadastro de clientes Xamego Bom')}&body=${linkCliente}`" color="primary">Compartilhar no Email <v-icon right>mdi-mail</v-icon></v-btn>
            <v-btn class="ml-2" target="_blank" :href="linkCliente" color="primary">Abrir em uma nova aba <v-icon right>mdi-link</v-icon></v-btn>
          </v-row>
          
          
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="dialogAviso" width="750px">
        <v-toolbar color="info" dark>
          <v-toolbar-title>Aviso</v-toolbar-title>
        </v-toolbar>
        <v-card>
            <v-card-text>
                <div v-html="markdownToHtml"></div>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAvisos" width="850px">
        <v-card>
            <v-card-text>
              <v-card-title>Avisos </v-card-title>
              <v-data-table :items="avisos" :headers="headersAvisos">
                <template v-slot:[`item.texto`]="{item}">
                  <div v-html="returnHtml(item.texto.replace('#','').replace('\n','').substring(0,50))"></div>
                </template>
                <template v-slot:[`item.ver`]="{item}">
                  <span>
                    <v-btn color="primary" small @click="() => {avisoEscolhido = item.texto; dialogAviso = true;item.visto = true}">ver</v-btn>
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAlerta" width="550px">
      <v-card>
        <v-alert class="mt-3" type="error" border="left" colored-border>
          <p class="text-h4">Acesse o menu AVISOS</p>
          <p class="text-h5">Existem mensagens importantes para você</p>
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { Marked } from 'marked';
// import AlterarSenha from './components/AlteracaoSenha'
export default {
  metaInfo() { 
    return { 
      title: 'Pedidos Xamego Bom', 
      meta: [
      { charset: 'utf-8' },
      { equiv: 'Content-Type', content: 'text/html' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ] 
    } 
  },
  
  components:{
    // AlterarSenha
  },
  created(){
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'pt-br')
    
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    darkThemeMq.addListener(e => {
      if (e.matches) {
        // Theme set to dark.
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
        // Theme set to light.
      }
    });


  },
  mounted(){
    
    this.getToken();
    if(this.token){
      let dec = JSON.parse(atob(this.token.split('.')[1]));
      this.usuario = dec;
      this.getQtdNovosClientes();
      this.obterAvisos();
    }
    
  },    
  data(){
    return{
      dialogAviso: false,
      dialogAvisos: false,
      dialogLink: false,
      dialogAlerta: false,
      linkCliente:'',
      token:null,
      qtdNovosClientes:0,
      usuario:{
        role:''
      },
      avisos:[],
      avisoEscolhido:'',
      headersAvisos:[
        {text:'Nome',value:'nome'},
        {text:'Texto',value:'texto'},
        // {text:'Criação',value:'criacao'},
        // {text:'Duração',value:'duracao'},
        {text:'Visto',value:'visto'},
        {text:'Ver',value:'ver'}
      ],
    }
  },
  computed:{

    markdownToHtml(){
    //  const teste = marked(this.avisos[0].texto);
      const teste = new Marked().parse(this.avisoEscolhido)
      return teste;
   }
  },
  methods:{
    marcarAvisoLido(aviso){
      this.$axios.post('/login/marcar-aviso-lido',{
        idAviso: aviso.aviso._id,
        idUsuario: aviso.usuario,
      },{headers:{'Authorization':`Bearer ${this.token}`}})
      .then(() => {
        this.dialogAviso = true;
        this.avisoEscolhido = aviso.aviso.texto;
        this.obterAvisos();
      }).catch(err => {
        console.log(err);
        alert('Não foi possível marcar aviso como lido')
      })
    },
    returnHtml(markdown){
      const result = new Marked().parse(markdown);
      return result;
    },
    obterAvisos(){
      this.avisos = [];
      this.$axios.get('/login/avisos/', {headers:{'Authorization':`Bearer ${this.token}`}})
      .then(resp => {
        this.avisos = resp.data;
        if(this.avisos.filter(a => !a.visto).length > 0){
          this.dialogAlerta = true;
        }
      }).catch(err => {
        console.log(err);
        alert(`Não foi possível obter avisos`);
      })
    },
    obterLinkCliente(){
      this.dialogLink = true;
      this.$axios.get('/representantes/cadastro-usuario',{headers:{'Authorization':`Bearer ${this.token}`}})
      .then(resp => {
        const token = resp.data;
        this.linkCliente = `https://pedidos.xamegobom.com.br/cadastro-clientes?id=${token.token}`;
        
      }).catch(err => {
        console.log(err);
      })
    },

    logout(){
      sessionStorage.clear();
      this.$router.push('/login');
      this.$router.go();
    },

    getToken(){
      let token = sessionStorage.getItem('token');
      if(token){
        this.token = token;
      }
    },

    changeTheme(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },

    getQtdNovosClientes(){
      this.$axios.get('/relatorios/novos-cadastros',{headers:{'Authorization':`Bearer ${this.token}`}})
      .then(resp => {
        this.qtdNovosClientes = resp.data;
        
      }).catch(err => {
        console.log(err);
      })
    },
    
  },
}

</script>

<style scoped>
.v-tabs-bar{
  float: right !important;
}
.v-tab{
  text-transform: none !important;
}
</style>