"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaixaPrecoEXPORT = exports.FaixaPreco = void 0;
class FaixaPreco {
}
exports.FaixaPreco = FaixaPreco;
FaixaPreco.FX1 = { min: 1100, max: 2500 };
FaixaPreco.FX2 = { min: 2500, max: 10100 };
FaixaPreco.FX3 = { min: 10100, max: Infinity };
class FaixaPrecoEXPORT {
}
exports.FaixaPrecoEXPORT = FaixaPrecoEXPORT;
FaixaPrecoEXPORT.FX1 = { min: 500, max: 5000 };
FaixaPrecoEXPORT.FX2 = { min: 5000, max: 10000 };
FaixaPrecoEXPORT.FX3 = { min: 10000, max: 25000 };
FaixaPrecoEXPORT.FX4 = { min: 25000, max: Infinity };
